@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth !important;
}

.markdown-styles li {
  padding: 10px 0 10px 25px;
  position: relative;
}

.markdown-styles li::before {
  content: "";
  background: url(../src/assets/Icon.png);
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.markdown-styless li::before {
  content: "";
  background: url(../src/assets/iteroicon.png);
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.markdown-styless li {
  padding: 15px 0 15px 25px;
  position: relative;
}

.copyright-text{
  font-size: 18px!important;
}

.denplan-heading p {
  font-weight: 600;
  font-size: larger;
}

.markdown p {
  padding: 5px 0;
}

.markdown ul {
  list-style-type: disc;
  padding: 0;
  margin-left: 15px;
}

.markdown ul li {
  padding: 0;
  margin: 0;
  line-height: normal;
}

.slick-next {
  right: 5px!important;
}

.slick-prev {
  left: 5px!important;
  z-index: 1;
}

.scrollbar::-webkit-scrollbar {
  width: 12px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 6px;
}

/* denplan page banner stylings */
.slick-dots li.slick-active button::before{
  color: #2146a3!important;  
 }
 .slick-dots li button::before{
  color: rgb(197, 217, 244)!important;  
 }
 .move-inside{
  bottom: 0 !important;
 }
 .firstSlide > p:first-child {
  font-size: 30px;
  color: white;
 }
 .firstSlide > p:last-child{
  color: #df5d49;
  font-size: 22px;
  font-weight: 500;
  margin-top: 12px;
 }
 @media (max-width:640px) {
  .firstSlide > p:first-child {
  font-size: 18px;
 }
 .firstSlide > p:last-child{
  color: red;
  font-size: 12px;
  margin-top: 12px;
 }
 }
 
 @media (min-width:950px){
  .firstSlide > p{
      width: 60%;
  }
 }
 
 .secondSlide > ul{
  list-style-type: disc;
  padding-left: 2px;
 }
 .secondSlide > ul > li{
  margin-bottom: 7px;
  font-size: 9px;
 }
 @media (min-width:640px) {
  .secondSlide > ul > li{
  margin-bottom: 10px;
  font-size: 14px;
  }
 }
 @media (min-width:900px) {
  .secondSlide > ul > li{
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 36px;
  }
 }
 .secondSlide > ul > li > span{
  color: red;
 }
 .thirdSlide > p {
  font-size: 14px;
  width: 50%;
  color: #ffffff;
 }
 @media (min-width:640px) {
  .thirdSlide > p {
      font-size: 20px;
      width: 50%;
  }
 }
 @media (min-width:768px) {
  .thirdSlide > p {
      font-size: 28px;
      width: 60%;
  }
 }
 @media (min-width:900px) {
  .thirdSlide > p {
      font-size: 34px;
      width: 60%;
  }
 }
 @media (min-width:1070px) {
  .thirdSlide > p {
      font-size: 36px;
      width: 50%;
  }
 }
 
 .signingUp > p:first-child {
  font-size: 36px;
  margin-bottom: 20px;
 }
 .signingUp > p{
  margin-bottom: 20px;
  font-size: 16px;
 }
 @media (max-width:836px) {
 .signingUp > p:first-child {
  font-size: 26px;
  margin-bottom: 20px;
 }
 }